import axios, { axiosAuth } from "./axios";

export async function login(data) {
  return axiosAuth.post("/token", data).then(({ data }) => data.data);
}

export function logout() {
  const url = `/auth/sign_out`;
  return axios.delete(url).then((res) => res);
}

export function resetPassword(data) {
  const url = `/auth/password/reset`;
  return axios.post(url, data).then(({ data }) => data.data);
}

export function signUp(data) {
  const url = `/auth/sign_up`;
  return axios.post(url, data).then(({ data }) => data.data);
}

export function invitationRequest(data) {
  const url = `/invitation_requests`;
  return axios.post(url, data).then(({ data }) => data.data);
}

export function loginAsGuest(data) {
  const url = `/auth/sign_up/guest`;
  return axios.post(url, data).then(({ data }) => data.data);
}

export async function getMe() {
  const url = `/me`;
  return axios.get(url).then(({ data }) => data.data);
}

export async function getConsultant() {
  const url = `/consultant`;
  return axios.get(url).then(({ data }) => data.data);
}

export async function getMessages({ page }) {
  let url = `/messages?page=${page}`;
  return axios.get(url).then(({ data }) => data);
}

export async function getAccount() {
  const { clientId, clientSecret } = window.luxlockAccountConfig;

  const url = `/account?client_id=${clientId}&client_secret=${clientSecret}`;
  return axios.get(url).then(({ data }) => data.data);
}

export function postMessage(data) {
  const url = `/messages`;
  return axios.post(url, data).then(({ data }) => data.data);
}

export function getProduct(id) {
  const url = `/products/${id}`;
  return axios.get(url).then(({ data }) => data.data);
}

export function productToggleWish(id) {
  const url = `/products/${id}/toggle_wish`;
  return axios.post(url).then(({ data }) => data.data);
}

export function postConsultantRate(data) {
  const url = `/consultant/rate`;
  return axios.post(url, data).then(({ data }) => data.data);
}
