<script>
  import { link } from "svelte-routing";

  export let active;
  export let isPremium = false;
</script>

<div class="header">
  <slot name="header" />
</div>

<div class="container">
  <nav class="toggle">
    <a
      class="toggle__item toggle__item-first"
      class:toggle__item-active={active === 'login'}
      href="/auth/sign_in"
      use:link
      data-name="loginSwitch">
      Login
    </a>
    <a
      class="toggle__item toggle__item-last"
      class:toggle__item-active={active === 'register'}
      href="/auth/sign_up"
      use:link
      data-name="registerSwitch">
      {#if isPremium}
        Request Access
      {:else}
        Get Matched
      {/if}
    </a>
  </nav>
</div>

<style>
  .container {
    margin-bottom: 40px;
  }

  .header {
    min-height: 80px;
    margin: 0 0 20px 0;
    font-size: 13px;
    font-weight: 300;
  }
  .toggle {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    max-width: 260px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.1);
  }

  .toggle__item {
    position: relative;
    z-index: 1;
    display: block;
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing: 0.9px;
    color: #333333;
    white-space: nowrap;
    background: #e2e2e2;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .toggle__item-active {
    z-index: 10;
    background: #f4f4f4;
  }
  .toggle__item-first {
    border-radius: 100px 0 0 100px;
  }

  .toggle__item-first.toggle__item-active {
    box-shadow: 10px 10px 20px 0 #bdbdbd;
  }
  .toggle__item-last {
    border-radius: 0 100px 100px 0;
  }

  .toggle__item-last.toggle__item-active {
    box-shadow: -10px 10px 20px 0 #bdbdbd;
  }
</style>
