import { writable } from "svelte/store";
import { IFrameHelper } from 'utils/helpers';
import { setAxiosAccessToken } from "utils/axios";

import consultantStore from "stores/consultant";
import messagesStore from "stores/messages";

function createAuthStore() {
  const { subscribe, set } = writable(undefined);

  return {
    subscribe,
    login: (authToken) => {
      IFrameHelper.sendMessage({
        event: 'onLogin',
        authToken,
      });

      setAxiosAccessToken(authToken.access_token);
      set(authToken);
    },
    logout: () => {
      consultantStore.reset();
      messagesStore.reset();

      IFrameHelper.sendMessage({
        event: 'onLogout',
      });

      set(undefined);
    },
  };
}

export default createAuthStore();
