export const getFullName = (contact) => contact && `${contact.firstName} ${contact.lastName}`;
export const getFirstNameWithInitial = (contact) => {
  const lastNameInitial = contact.lastName ? `${contact.lastName[0]}.` : "";
  return contact && `${contact.firstName} ${lastNameInitial}`;
};
export const getInitials = (contact) =>
  getFullName(contact)
    .split(" ")
    .map((n) => n[0])
    .join("");

export const getDisplayName = (contact) => {
  if (contact.alias) {
    return contact.alias;
  }

  return getFirstNameWithInitial(contact);
};
