<script>
  import accountStore from "stores/account";
  import consultantStore from "stores/consultant";
  import { IFrameHelper } from 'utils/helpers';
  import { getDisplayName } from "utils/contact";

  import ArrowDownIcon from "assets/icons/arrowDown.svg";
  import Avatar from "../shared/Avatar.svelte";
</script>


<div class="header">
  {#if $consultantStore && $consultantStore.state !== 'stale'}
    <div class="header__container">
      <Avatar contact={$consultantStore} size={32} />
      <span class="header__txt">{getDisplayName($consultantStore)}</span>
    </div>
  {:else if $accountStore}
    <h1 class="header__title">{$accountStore.name}</h1>
  {/if}

  <button class="button header__close" on:click={() => IFrameHelper.sendMessage({ event: 'toggleBubble' })} data-name="turnButton">
    {@html ArrowDownIcon}
  </button>
</div>

<style>
  .header {
    z-index: 200010;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 18px;
    width: 100%;
    height: 70px;
  }

  .header__title {
    display: block;
    height: 68px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #b09353;
  }

  .header__container {
    display: flex;
    align-items: center;
  }

  .header__txt {
    margin-left: 10px;
    font-size: 18px;
    color: #242428;
  }

  .header__close {
    position: absolute;
    top: 27px;
    right: 18px;
    min-width: 18px;
    bottom: auto;
  }
</style>
