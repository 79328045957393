<script>
  import { onDestroy, createEventDispatcher } from "svelte";

  export let threshold = 0;
  export let elementScroll;
  export let hasMore = true;

  const dispatch = createEventDispatcher();

  let isLoadMore = false;
  let component;

  $: {
    if (component || elementScroll) {
      const element = elementScroll ? elementScroll : component.parentNode;
      element.addEventListener("scroll", onScroll);
      element.addEventListener("resize", onScroll);
    }
  }

  const onScroll = (e) => {
    if (e.target.scrollTop <= threshold) {
      if (!isLoadMore && hasMore) {
        dispatch("loadMore");
      }
      isLoadMore = true;
    } else {
      isLoadMore = false;
    }
  };

  onDestroy(() => {
    hasMore = false;

    const element = elementScroll ? elementScroll : component.parentNode;
    element.removeEventListener("scroll", null);
    element.removeEventListener("resize", null);
  });
</script>

<div bind:this={component} style="width:0px" />
