<script>
  import { navigate } from "svelte-routing";
  import { getNotificationsContext } from "svelte-notifications";

  import { resetPassword } from "utils/api";

  import Button from "../shared/Button.svelte";
  import FormErrors from "../shared/FormErrors.svelte";
  import FormInput from "../shared/FormInput.svelte";
  import FormActions from "../shared/FormActions.svelte";

  let errors;
  let username;

  const { addNotification } = getNotificationsContext();

  const onSubmit = async () => {
    try {
      await resetPassword({
        username: username.trim(),
        client_id: window.luxlockAccountConfig.clientId,
        client_secret: window.luxlockAccountConfig.clientSecret,
      });

      addNotification({
        id: "checkEmail",
        text: `A password reset link has been sent to ${username}. Please check your email.`,
        type: "success",
        position: "top-center",
        removeAfter: 4000,
      });

      navigate("/auth/sign_in");
    } catch ({ response }) {
      errors = response.data.errors;
    }
  };
</script>

<h2 class="title">Reset Password</h2>

<form on:submit|preventDefault={onSubmit}>
  <FormErrors {errors} />

  <FormInput placeholder="Email" bind:value={username} id="username" withHint={true} name="username">
    <span slot="hint">Enter your email to receive a reset password link.</span>
  </FormInput>

  <FormActions>
    <Button block type="submit" dataName="resetButton">Reset Password</Button>
    <br />
    <Button href="/auth/sign_in" block transparent data-name="backtoLogin">Back to Login</Button>
  </FormActions>
</form>

<style>
  .title {
    margin-bottom: 38px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 300;
    color: #1f1f22;
  }

  form {
    margin: 0 auto 30px;
  }
</style>
