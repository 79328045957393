import axios from "../utils/axios";

const API_URL = process.env.API_URL;
const eventUrl = `${API_URL}/events`;

export class EventEmmiter {
  constructor(eventName) {
    this.eventName = eventName;
  }

  emit(data) {
    const formData = {
      client_id: window.luxlockAccountConfig.clientId,
      client_secret: window.luxlockAccountConfig.clientSecret,
      type: this.eventName,
      ...data,
    };

    return axios.post(eventUrl, formData);
  }
}

export default EventEmmiter;
