import { format, isDate, isToday, isYesterday } from "date-fns";

const toDate = (date) => (isDate(date) ? date : new Date(date));

export const formatDateTime = (value) => {
  const date = toDate(value);

  return format(date, "h:mm a");
};

export const formatChatDate = (value) => {
  const date = toDate(value);

  if (isToday(date)) {
    return "Today";
  }

  if (isYesterday(date)) {
    return "Yesterday";
  }

  return format(date, "E, M/dd/yy");
};

export const formatToHumanDate = (value) => {
  const date = toDate(value);

  return format(date, "MMMM dd, yyyy");
};
