import Cookies from 'js-cookie';
import axios from "axios";
import { isDefined } from "utils/ramda";

const AUTH_URL = process.env.AUTH_URL;
const API_URL = process.env.API_URL;

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const axiosAuth = axios.create({
  baseURL: AUTH_URL,
});

export const axiosApi = axios.create({
  baseURL: API_URL,
});

export function setAxiosAccessToken(token) {
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosApi.defaults.headers.common["Authorization"];
  }
}

axiosApi.interceptors.response.use(
  (response) => response,
  function(error) {
    const { token, clientId, clientSecret} = window.luxlockAccountConfig
    let originalRequest = error.config;

    const getUserCookieName = () => {
      const SET_USER_COOKIE_PREFIX = 'll_user_';
      return `${SET_USER_COOKIE_PREFIX}${clientId}${clientSecret}`;
    };

    const refreshToken = isDefined(token) ? token.refresh_token : null;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axiosAuth
        .post(`${AUTH_URL}/token`, {
          refresh_token: refreshToken,
          grant_type: "refresh_token",
          client_id: clientId,
          client_secret: clientSecret,
        })
        .then((res) => {
          if (res.status === 200) {
            // 1) put token to LocalStorage

            // setToken(res.data.data);
            Cookies.set('ll_authToken', res.data.data);
            const newAccessToken = res.data.data.access_token;

            // 2) Change Authorization header
            // NOTE: setToken(res.data.data) has done it already
            axiosApi.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;

            // 3) Change originalRequest header
            originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

            // 4) return originalRequest object with Axios.
            return axiosApi(originalRequest);
          }
        })
        .catch(() => {
          Cookies.remove('ll_authToken');
          Cookies.remove(getUserCookieName());
          // removeToken();
        });
    }

    // return Error object with Promise
    return Promise.reject(error);
  },
);

export default axiosApi;
