import { writable } from "svelte/store";
import { last } from "ramda";
import { getIn } from "utils/ramda";

const initialState = [];

function createMessagesStore() {
  const { subscribe, update, set } = writable(initialState);

  return {
    subscribe,
    set: (messages) => set(messages),
    fetch: (newMessages) => update((messages) => [...newMessages, ...messages]),
    update: (newMessages) => update((messages) => {
      if (messages && messages.length > 0) {
        if (getIn("id", last(messages)) !== newMessages.id) {
          return [...messages, newMessages];
        }

        return messages;
      }

      return [newMessages];
    }),
    reset: () => set(initialState),
  };
}

export default createMessagesStore();
