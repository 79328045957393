<script>
  import { getFullName, getInitials } from "utils/contact";

  export let contact;
  export let size = 30;

  const fullName = getFullName(contact);
  const initials = getInitials(contact);

  let initialSize = () => (size >= 30 ? 14 : 12);
  let borderRadius = size >= 60 ? 24 : 12;

  let class_name = "";
  export { class_name as class };
</script>

{#if contact.avatar}
  <img
    alt={fullName}
    class="avatar {class_name}"
    src={contact.avatar}
    width={size}
    height={size}
    style="--avatar_size: {size}px; --initial_size: {initialSize()}px; --border_radius: {borderRadius}px" />
{:else}
  <span
    class="avatar avatar-initials {class_name}"
    title={fullName}
    style="--avatar_size: {size}px; --initial_size: {initialSize()}px; --border_radius: {borderRadius}px">
    {initials}
  </span>
{/if}

<style>
  .avatar {
    display: block;
    margin: 0;
    font-size: 0;
    width: var(--avatar_size);
    height: var(--avatar_size);
    min-width: var(--avatar_size);
    max-width: var(--avatar_size);
    border-radius: var(--border_radius);
    background: #545454;
  }

  .avatar-center {
    margin: 0 auto;
  }

  .avatar-initials {
    text-align: center;
    font-size: var(--initial_size);
    line-height: var(--avatar_size);
    color: #eee;
  }
</style>
