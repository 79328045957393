<script>
  import { link, navigate } from "svelte-routing";

  import authStore from "stores/auth";
  import accountStore from "stores/account";
  import meStore from "stores/me";

  import { login, getMe } from "utils/api";

  import Button from "pages/shared/Button.svelte";
  import FormErrors from "pages/shared/FormErrors.svelte";
  import FormInput from "pages/shared/FormInput.svelte";
  import FormActions from "pages/shared/FormActions.svelte";

  import Toggle from "./shared/Toggle.svelte";

  let username;
  let password;
  let errors;

  const { flow_id, guest_token } = window.luxlockCustomer;

  const onSubmit = async () => {
    try {
      const token = await login({
        grant_type: "password",
        username: username.trim(),
        password,
        client_id: window.luxlockAccountConfig.clientId,
        client_secret: window.luxlockAccountConfig.clientSecret,
        flow_id,
        guest_token,
      });

      authStore.login(token);

      const me = await getMe();
      meStore.set(me);

      navigate("/", { replace: true });
    } catch ({ response }) {
      errors = response && response.data && response.data.errors;
    }
  };
</script>

<Toggle active="login" isPremium={$accountStore && $accountStore.isPremium}>
  <div class="header" slot="header">
    <h2 class="title">
      {#if $accountStore && $accountStore.loginPageTitle}
        {@html $accountStore.loginPageTitle}
      {:else}Welcome{/if}
    </h2>
    {#if $accountStore && $accountStore.loginPageText}
      {@html $accountStore.loginPageText}
    {:else}Shopping is better when we do it together, welcome back.{/if}
  </div>
</Toggle>

<form on:submit|preventDefault={onSubmit}>
  <FormErrors {errors} />

  <FormInput placeholder="Email Address" bind:value={username} id="username" {errors} />
  <FormInput
    placeholder="Password"
    bind:value={password}
    type="password"
    id="password"
    withHint={true}
    {errors}>

    <div class="hint" slot="hint">
      <a class="forgot_password" href="/auth/reset" use:link data-name="forgotPassword">Forgot password?</a>
    </div>
  </FormInput>

  <FormActions>
    <Button block dataName="connectButton" type="submit">Login</Button>
  </FormActions>
</form>

<style>
  .header {
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
    color: #757575;
  }
  .title {
    margin-bottom: 7px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 300;
    color: #1f1f22;
  }
  .hint {
    display: block;
    text-align: right;
    margin-top: 8px;
  }
  .forgot_password {
    text-decoration: none;
    font-size: 12px;
    line-height: 17px;
    color: #b09353;
  }
  .forgot_password:hover {
    cursor: pointer;
    text-decoration: underline;
  }
</style>
