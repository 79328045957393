<script>
  import { onMount } from "svelte";
  import { navigate, Router, Route } from "svelte-routing";
  import { getNotificationsContext } from "svelte-notifications";

  import { IFrameHelper } from 'utils/helpers';
  import { setAxiosAccessToken } from "utils/axios";
  import { isDefined } from "utils/ramda";
  import { getAccount, getMe } from "utils/api";

  import EventEmmiter from "services/EventEmmiter";

  import authStore from "stores/auth";
  import accountStore from "stores/account";
  import meStore from "stores/me";

  import Header from "pages/shared/Header.svelte";
  import Loader from "pages/shared/Loader.svelte";
  import AuthLayout from "pages/auth/Layout.svelte";
  import Chat from "pages/chat/Index.svelte";
  import Error from "pages/error/Index.svelte";
  import Proactive from "pages/proactive/Index.svelte";
  import ConsultantRate from "pages/consultantRate/Index.svelte";
  import ConsultantUnavailable from "pages/consultantUnavailable/Index.svelte";

  const { addNotification } = getNotificationsContext();

  let isLoading = false;

  const isIFrame = () => IFrameHelper.isIFrame();

  onMount(async () => {
    if (isIFrame) {
      registerListeners();
      sendLoadedEvent();
      // setHeader('X-Auth-Token', window.authToken);
    }
  });

  const registerListeners = () => {
    window.addEventListener('message', e => {
      if (!IFrameHelper.isAValidEvent(e)) {
        return;
      }
      const message = IFrameHelper.getMessage(e);

      if (message.event === 'set-config') {
        setLuxlockAccountConfig(message);
        setCustomer(message.customer);
        setBubbleLabel();
        fetchAccount();
      } else if (message.event === 'link') {
        window.referrerURL = message.page_data.referrerURL;
        createWidgetEvents(message);
      } else if (message.event === 'push-event') {
        createWidgetEvents(message);
      } else if (message.event === 'set-user') {
        // Set user
      } else if (message.event === 'set-custom-attributes') {
        // Set custom attributes
      } else if (message.event === 'delete-custom-attribute') {
        // Delete custom attributes
      } else if (message.event === 'set-locale') {
        // Set locale
        setBubbleLabel();
      } else if (message.event === 'set-unread-view') {
        // Set unread
      } else if (message.event === 'unset-unread-view') {
        // Unset unread
      } else if (message.event === 'add-to-cart-success'){
        addNotification({
          id: "addToCart",
          text: `${message.productTitle} added to cart!`,
          type: "success",
          position: "top-right",
          removeAfter: 4000,
        });
      } else if (message.event === 'add-to-cart-failure'){
        addNotification({
            id: "addToCart",
            text: `${message.productTitle} cannot be added to cart. Please choose any other one`,
            type: "warning",
            position: "top-right",
            removeAfter: 4000,
          });
      }
    });
  }

  const fetchAccount = async () => {
    try {
      isLoading = true;
      const account = await getAccount();
      await accountStore.set(account);
      login();
      isLoading = false;
    } catch (error) {
      isLoading = false;
      navigate("/error");
    }
  }

  const login = async () => {
    const { token } = window.luxlockAccountConfig;

    if (isDefined(token)) {
      try {
        isLoading = true;
        const me = await getMe();
        meStore.set(me);
        authStore.login(token);
        isLoading = false;
      } catch ({ response }) {
        isLoading = false;
        authStore.logout();
      }
    }
    if (!isDefined(token) || !$authStore) {
      onLogout();
    }
  }

  function onLogout() {
    const { showProactiveMessage, isProactiveMessage } = window.luxlockAccountConfig;

    if (showProactiveMessage && isProactiveMessage) {
      navigate("/proactive");
    } else {
      navigate("/auth/sign_in");
    }
  }

  const setLuxlockAccountConfig = (message) => {
    const { clientId, clientSecret, platform, position, showProactiveMessage, proactiveMessageTitle, proactiveMessageBody, isProactiveMessage, token } = message;

    if (isDefined(token)) {
      setAxiosAccessToken(token.access_token);
    }

    window.luxlockAccountConfig = {
      clientId,
      clientSecret,
      platform,
      position,
      showProactiveMessage,
      proactiveMessageTitle,
      proactiveMessageBody,
      isProactiveMessage,
      token,
    };
  };

  const setCustomer = (customer) => {
    window.luxlockCustomer = customer;
  };

  const createWidgetEvents = async (message) => {
    const { event, eventName, customer_data, page_data } = message;

    const isWidgetTriggerEvent = eventName === 'webwidget.triggered';

    if (isWidgetTriggerEvent) {
      return;
    }

    const eventEmmiter = new EventEmmiter(event);

    eventEmmiter.emit({
      page_data,
      customer_data: {
        ...window.luxlockCustomer,
        ...customer_data,
      }
    });
  }

  const setBubbleLabel = () => {
    IFrameHelper.sendMessage({
      event: 'setBubbleLabel',
      label: "Chat With Us",
    });
  }

  const sendLoadedEvent = () => {
    IFrameHelper.sendMessage({
      event: 'loaded',
    });
  }
</script>


<div class="container">
  <Router>
    <Header />

    <div class="container__body">
      {#if isLoading}
        <Loader />
      {:else}
        <Route path="/proactive" component={Proactive} />
        <Route path="/error" component={Error} />
        <Route path="/unavailable" component={ConsultantUnavailable} />
        <Route path="/consultant/rate" component={ConsultantRate} />
        <Route path="/auth/*" component={AuthLayout} />
        {#if $authStore}
          <Route path="/" component={Chat} />
        {/if}
      {/if}
    </div>
  </Router>
</div>


<style>
  :global(body){
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 200000;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    color: #999;
    font-size: 16px;
    line-height: 1.35;
  }
  .container::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #eedc85 0%, #c79c39 100%);
  }
  .container__body {
    flex: 1;
    padding: 0 50px 10px;
  }
</style>
