<script>
  export let disabled = false;
  export let value = "";
  export let id;
  export let options = [{}];
  export let onChange;
</script>

<div class="select">
  <select on:change={onChange} bind:value {disabled} {id}>
    {#each options as option}
      <option value={option.value}>{option.label}</option>
    {/each}
  </select>
  <div class="select__arrow" />
</div>

<style>
  .select {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  select {
    display: inline-block;
    width: 100%;
    min-width: 110px;
    cursor: pointer;
    padding: 10px 30px 10px 15px;
    font-size: 16px;
    outline: 0;
    border: 0;
    background: #ededed;
    color: #242428;
    appearance: none;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  select::-ms-expand {
    display: none;
  }

  select:hover,
  select:focus {
    color: #242428;
    background: #ededed;
  }

  select:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .select__arrow {
    position: absolute;
    top: 15px;
    right: 10px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0 5px;
    border-color: #d9d9d9 transparent transparent transparent;
  }

  select:hover ~ .select__arrow,
  select:focus ~ .select__arrow {
    border-top-color: #d9d9d9;
  }

  select:disabled ~ .select__arrow {
    border-top-color: #ccc;
  }
</style>
