<script>
  import { onDestroy } from "svelte";
  import { getNotificationsContext } from "svelte-notifications";
  import SuccessIcon from "../../assets/icons/check.svg";

  export let notification = {};

  let timeout = null;

  const { id, removeAfter } = notification;
  const { removeNotification } = getNotificationsContext();

  const removeNotificationHandler = () => removeNotification(id);

  if (removeAfter) {
    timeout = setTimeout(removeNotificationHandler, removeAfter);
  }

  onDestroy(() => {
    if (removeAfter && timeout) clearTimeout(timeout);
  });

  // `onRemove` function will be passed into your component.
  // let onRemove = null;

  // const handleButtonClick = () => {
  //   onRemove();
  // }

  // console.log(notification);
</script>

<div class="notification">
  {#if notification.type === 'success'}
    <div class="notification__icon">
      {@html SuccessIcon}
    </div>
  {/if}
  <span>{notification.text}</span>
  <!-- <button on:click={handleButtonClick}>Close me</button> -->
</div>

<style>
  :global(.notifications) {
    position: relative;
    z-index: 200001;
  }

  :global(.position-top-right) {
    top: 25px !important;
    right: 60px !important;
  }

  .notification {
    display: flex;
    align-content: flex-start;
    align-items: center;
    padding: 5px;
    border-radius: 8px;
    font-size: 11px;
    font-weight: 600;
    color: #666;
    background: #fff;
    border: 1px solid #d8d8d8;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .notification__icon {
    margin: 4px 5px 0 0;
  }
</style>
