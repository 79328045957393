<script>
  import { prop, descend, sortWith, propEq, find, findIndex } from "ramda";

  import { IFrameHelper } from 'utils/helpers';

  import accountStore from "stores/account";
  import { getProduct, productToggleWish } from "utils/api";
  import { formatToHumanDate } from "utils/formats";

  import Button from "../../shared/Button.svelte";
  import Loader from "../../shared/Loader.svelte";
  import FormInput from "../../shared/FormInput.svelte";

  import HeartIcon from "assets/icons/heart.svg";
  import HeartFilledIcon from "assets/icons/heart-filled.svg";
  import PlusIcon from "assets/icons/plus.svg";
  import MinusIcon from "assets/icons/minus.svg";

  export let collection;
  export let id;
  export let selectProduct;

  let isLoading = false;
  let isWished = false;

  let quantity = 1;
  let product = {};
  let prevProduct = undefined;
  let nextProduct = undefined;
  let variantsOptions = [];
  let variantId;
  let optionSelections = [];
  let images = [];

  const { platform } = window.luxlockAccountConfig;

  $: onMount(id);

  $: getPrice = () => {
    if (product && product.variants && product.variants.length > 0) {
      const selectedVariant = find(propEq("id", variantId))(product.variants);

      return ({
        formattedPrice: selectedVariant.formattedPrice || product.formattedPrice,
        formatedCompareAtPrice: selectedVariant.formatedCompareAtPrice
        })
    } else {
      return ({
        formattedPrice: product.formattedPrice
      })
    }
  };


  const onMount = async (id) => {
    variantsOptions = [];

    const currentIndex = collection.findIndex((item) => item.id == id);
    const collectionLength = collection.length;


    if (collectionLength > 1) {
      prevProduct = collection[currentIndex === 0 ? collectionLength - 1 : currentIndex - 1];
      nextProduct = collection[currentIndex === collectionLength - 1 ? 0 : currentIndex + 1];
    }

    try {
      isLoading = true;
      product = await getProduct(id);
      isLoading = false;
    } catch {
      isLoading = false;
    }

    isWished = product.isWished;
    quantity = 1;

    // FIXME. Prepare adapter for platform specific fields.
    // Shopify variants
    if (product && product.variants && product.variants.length > 0) {
      variantsOptions = product.variants.map((v) => ({ value: v.id, label: v.title }));
      variantId = variantsOptions[0].value;
    }

    // Bigcommerce variants
    if (product && product.options && product.options.length > 0) {
      variantsOptions = product.options.map((o) => ({
        id: o.id,
        variants: o.values.map((v) => ({ label: v.label, value: v.option_value_id })),
        label: o.display_name,
      }));

      optionSelections = variantsOptions.map((o) => ({
        optionId: o.id,
        optionValue: o.variants[0].value,
      }));
    }

    const isPrimarySort = sortWith([descend(prop("isPrimary"))]);
    // images = product.images.slice(0, 4);
    images = isPrimarySort(product.images).slice(0, 4);
  };

  const toggleWish = async () => {
    await productToggleWish(id);
    isWished = !isWished;
  };

  const addToCart = async () => {
    const refreshPage = $accountStore.refreshPage;

    if (platform && platform === "shopify") {
      IFrameHelper.sendMessage({
        event: 'addToCart',
        productId: id,
        refreshPage,
        variantId,
        quantity,
        productTitle: product.title,
      });
    }

    if (platform && platform === "big_commerce") {
      IFrameHelper.sendMessage({
        event: "addToCart",
        productId: id,
        refreshPage,
        quantity,
        optionSelections,
        productTitle: product.title,
      });
    }
  };

  const changeFeaturedImage = (id) => {
    const selectedIndex = images.findIndex((image) => image.id === id);
    images = [...images.splice(selectedIndex, 1), ...images];
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      return (quantity = quantity - 1);
    }

    return quantity;
  };

  // FIXME
  const handeChangeOptionSelections = (e, optionId) => {
    const index = findIndex(propEq("optionId", optionId))(optionSelections);
    const optionValue = Number(e.target.value);

    if (index === -1) {
      optionSelections = [
        ...optionSelections,
        {
          optionId,
          optionValue,
        },
      ];
    } else {
      optionSelections[index].optionValue = optionValue;
    }

    return optionSelections;
  };
  const increaseQuantity = () => (quantity = quantity + 1);
</script>

{#if isLoading}
  <Loader />
{:else}
  <div class="header">
    {#if product.vendor}
      <span class="header__vendor">{product.vendor}</span>
    {/if}

    <div class="header__title">
      {#if prevProduct}
        <button class="arrow arrow-prev" on:click={() => selectProduct(prevProduct.id)}>Prev</button>
      {/if}

      <h1>{product.title}</h1>

      {#if nextProduct}
        <button class="arrow arrow-next" on:click={() => selectProduct(nextProduct.id)}>Next</button>
      {/if}
    </div>
  </div>

  {#if images}
    <div class="gallery gallery-{images.length}">
      {#each images as image}
        <button class="gallery__item" on:click={() => changeFeaturedImage(image.id)}>
          <img alt={product.title} class="gallery__img" src={image.src} />
        </button>
      {/each}
    </div>
  {/if}

  {#if product.isOwned}
    <h4 class="owned">Re-order</h4>
    {#if product.ownedAt}
      <p class="ownedAt">Purchased on {formatToHumanDate(product.ownedAt)}</p>
    {/if}
  {/if}
  <form on:submit|preventDefault={addToCart}>
    <div class="variants">
      {#if variantsOptions && variantsOptions.length > 0}
        <!-- Shopify -->
        {#if platform === 'shopify'}
          {#if variantsOptions.length > 1 || variantsOptions[0].label }
            <div class="variants__item">
              <FormInput
                bind:value={variantId}
                id="variantId"
                options={variantsOptions}
                label="Style Options"
                type="select"
                theme="light" />
            </div>
          {/if}
        {:else}
          <!-- BigCommerce -->
          <div class="variants__item">
            {#each variantsOptions as option}
              <FormInput
                onChange={(e) => handeChangeOptionSelections(e, option.id)}
                id="optionSelections"
                options={option.variants}
                label={option.label}
                type="select"
                theme="light" />
            {/each}
          </div>
        {/if}
      {/if}

      <div class="variants__item form__group form__group-light">
        <span class="form__label">Quantity</span>
        <div class="quantity">
          <button class="quantity__btn" on:click={decreaseQuantity} type="button">
            {@html MinusIcon}
          </button>
          <span class="quantity__count">{quantity}</span>
          <button class="quantity__btn" on:click={increaseQuantity} type="button">
            {@html PlusIcon}
          </button>
        </div>
      </div>
    </div>

    <div class="price">
      <span class="price__title">Price</span>
      <strong class="price__value">
        {getPrice().formattedPrice}
        {#if getPrice().formatedCompareAtPrice}
          <s class="price__compare">{getPrice().formatedCompareAtPrice}</s>
        {/if}
      </strong>


      {#if platform}
        <Button class="btn-price" block dark type="submit">Add To Bag</Button>
      {/if}
    </div>

    <Button
      class="btn-price {!isWished && 'transparentGrey'}"
      transparent
      block
      on:click={toggleWish}
      type="button">
      {@html isWished ? HeartFilledIcon : HeartIcon}
      {isWished ? 'Wishlisted' : 'Add to Wishlist'}
    </Button>

  </form>
{/if}

<style>
  .header {
    padding: 0;
    min-height: 33px;
    margin-bottom: 18px;
  }

  h1 {
    padding: 0 10px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    color: #242428;
  }

  .header__vendor {
    display: block;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: #242428;
  }

  .header__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .arrow {
    display: block;
    width: 14px;
    min-width: 14px;
    height: 11px;
    text-indent: -30000px;
  }

  .arrow-prev {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAALCAYAAABPhbxiAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAADqADAAQAAAABAAAACwAAAABW2JSwAAAA9UlEQVQoFWNmQAOFhYWc+vr6q0xMTFjPnj17CU0azmWCs4CMzMxMwW/fvu0GMt84OzsvR5ZDZzPCBDIyMqT//fu3k5GRcf3MmTNrYeK4aGaQRFpamgaQ2gfEk2bNmtUJEiMEGIGazIGK1gNtKgY6bxUhDSD50NDQfyCND4FsOaDGP8RoAqn5////HRYgHQjUtAlIVwD9tgQkQQxgBgb5c2Nj441AxXOBNCgKjhOjERwdwAC5y8nJaQ3UEJOent4NdAo8tHEZgqIgNzeX7+fPnxuATn8sKSmZ3NDQgNPfKAlg8uTJn9jZ2T2BtrC+ePEiHJdtIHEAJIFL+MMZtXAAAAAASUVORK5CYII=")
      no-repeat center;
  }

  .arrow-next {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAALCAYAAABPhbxiAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAADqADAAQAAAABAAAACwAAAABW2JSwAAAA6UlEQVQoFWNkwAMyMjJi////H8jFxRXd39//HVkpEzIHne3k5LQMqPHt169fdxUUFAggyzMic3Cx09PTm4FyAYyMjB4zZsx4ClLHjEsxsvjZs2f3GxsbMwFtnw2kdwL5bxgbGhqYtLS0iLJ57969YUDNvUBDA1meP39+DYiVkW0gwGYByq8kyiaYQaBQ/vfvXxuQ7w/STRRIS0srBmrKACp2mDVr1l2CgQP0E+OLFy+6gSHqz8nJ6Txt2rTHIJvwOhUYcCzPnj2bB9QkzcbGFjh58uRPMOfhTQDAQIsAKmSWlJT0RNYE0gwAsqVYGXjIjvEAAAAASUVORK5CYII=")
      no-repeat center;
  }

  .price {
    margin: 0;
    padding: 10px 0 0 0;
    text-align: center;
  }

  .price__title {
    display: block;
    margin-bottom: 4px;
    text-transform: uppercase;
    font-size: 11px;
    color: #242428;
  }

  .price__value {
    display: block;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: #242428;
  }

  .price__compare{
    margin-left: 10px;
    color: #999999;
  }

  @keyframes shine {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .gallery {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 100%);
    margin-bottom: 20px;
  }

  .gallery-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery-3 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 70px);
  }

  .gallery-3 .gallery__item:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .gallery-4 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 70px);
  }
  .gallery-4 .gallery__item:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .gallery__item {
    width: 100%;
    height: 100%;
  }

  .gallery__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .variants {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .owned {
    text-align: center;
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    color: #111;
  }

  .ownedAt {
    margin-bottom: 20px;
    text-align: center;
  }

  .quantity {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
  }

  .quantity__btn {
    margin: 0 5px;
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    color: #666;
  }

  .quantity__count {
    font-size: 16px;
    line-height: 20px;
    color: #666;
  }

  .wishlist {
    padding: 0;
    cursor: pointer;
    margin: 5px 0 0 0;
    width: 100%;
    outline: none;
    border: none;
    background: none;
  }
  .wishlist {
    fill: #999;
  }

  .wishlist-active {
    fill: #c0392b;
  }

  .variants__item {
    flex: 1;
    margin-right: 10px;
  }

  .variants__item:last-child {
    margin-right: 0;
  }
</style>
