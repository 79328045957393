<script>
  import { path, values } from "ramda";
  import FormSelect from "./FormSelect.svelte";

  export let autofocus = false;
  export let required = false;
  export let dataName = undefined;
  export let disabled = false;
  export let id;
  export let name = "";
  export let label = undefined;
  export let withHint = false;
  export let errors;
  export let onChange;
  let error;

  export let type = "text";
  export let value = "";
  export let rows = 4;
  export let maxlength = 50;
  export let placeholder = "";
  export let options = [{}];
  export let theme = "dark";

  let class_name = "";
  let passwordType = "password";
  export { class_name as class };


  const changePasswordType = () => {
    if (passwordType === 'password') {
      passwordType = 'text'
    } else {
      passwordType = 'password'
    }
  }

  $: {
    errors = errors && path(name.split("."), errors.source);
    if (errors) {
      error = values(errors)[0];
    } else {
      error = null;
    }
  }
</script>

<div class="form__group form__group-{theme}" class:form__group-error={error}>
  {#if label && type !== 'checkbox'}
    <label for={id} class="form__label">{label}</label>
  {/if}

  {#if type === 'text'}
    <input
      {autofocus}
      data-name={dataName}
      {id}
      bind:value
      {maxlength}
      {disabled}
      {required}
      {placeholder}
      class="form__input {class_name}" />
  {/if}

  {#if type === 'password'}
    {#if passwordType === 'password'}
      <div class="form__password">
        <input
          {id}
          data-name={dataName}
          bind:value
          {maxlength}
          {disabled}
          {required}
          {placeholder}
          class="form__input {class_name}"
          type='password' />
        <button class="password__btn" type="button" on:click={changePasswordType}>
          <svg xmlns="http://www.w3.org/2000/svg" class="password__svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
          </svg>
        </button>
      </div>
    {/if}

    {#if passwordType === 'text'}
      <div class="form__password">
        <input
          {id}
          data-name={dataName}
          bind:value
          {maxlength}
          {disabled}
          {required}
          {placeholder}
          class="form__input {class_name}"
          type='text' />
        <button class="password__btn" type="button" on:click={changePasswordType}>
          <svg xmlns="http://www.w3.org/2000/svg" class="password__svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
          </svg>
        </button>
      </div>
    {/if}
  {/if}

  {#if type === 'textarea'}
    <textarea
      {autofocus}
      {id}
      data-name={dataName}
      bind:value
      {rows}
      {disabled}
      {required}
      {placeholder}
      class="form__input {class_name}" />
  {/if}

  {#if type === 'checkbox'}
    <label class="checkbox__label">
      {label}
      <input
        class="form__checkbox"
        type="checkbox"
        bind:checked={value}
        {disabled}
        {name}
        on:change={onChange} />
      <span class="checkbox__span"></span>
    </label>
  {/if}

  {#if type === 'select'}
    <FormSelect bind:value {disabled} {id} {options} {onChange} />
  {/if}

  {#if error}
    <span class="form__error">{error}</span>
  {/if}

  {#if withHint}
    <div class="form__hint">
      <slot name="hint" />
    </div>
  {/if}
</div>

<style>
  :global(.form__group) {
    margin: 0 0 16px;
    text-align: left;
  }

  :global(.form__group-error .form__label) {
    color: red;
  }

  :global(.form__label) {
    display: block;
    margin-bottom: 5px;
    max-width: 100%;
  }

  :global(.form__group-light .form__label) {
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    color: #242428;
  }

  .form__input {
    padding: 18px 16px;
    width: 100%;
    height: 56px;
    font-size: 16px;
    transform: scale(1);
    line-height: 1;
    color: #545454;
    outline: 0;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: inset -4px -4px 8px 0 #fafbff, inset 4px 4px 8px 0 #bdbdbd;
    border-radius: 4px;
    transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width:1024px) {
    .form__input{
      font-size: 16px;
    }
  }

  .form__input::placeholder {
    color: #999;
  }

  textarea.form__input {
    height: auto;
    line-height: 1.35;
  }

  /* .form__input:focus {
    border-color: #444;
  } */

  .form__input:-webkit-autofill,
  .form__input:-webkit-autofill:hover,
  .form__input:-webkit-autofill:focus {
    font-size: 16px;
    color: #545454;
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: inset -4px -4px 8px 0 #fafbff, inset 4px 4px 8px 0 #bdbdbd;
    transition: background-color 5000s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    -webkit-text-fill-color: #545454;
  }

  .form__input:-webkit-autofill:focus {
    border-color: #444;
  }

  .form__hint,
  .form__error {
    display: block;
    font-size: 12px;
    margin: 4px 0 0 3px;
  }

  .form__error {
    font-weight: 500;
    color: red;
  }

  .checkbox__label {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .checkbox__label input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox__span {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
  }

  .checkbox__label:hover input ~ .checkbox__span {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox__label input:checked ~ .checkbox__span {
    background-color: #b09353;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkbox__span:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox__label input:checked ~ .checkbox__span:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox__label .checkbox__span:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .form__password{
    position: relative;
  }

  .password__btn{
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    padding: 8px;
    background: none;
    color: #999;
    border:none;
  }
  .password__svg{
    width: 24px;
    height: 24px;
  }

</style>
