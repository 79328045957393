import WidgetApp from "./WidgetApp.svelte";

window.luxlockAccountConfig = {};
window.luxlockCustomer = {};

const widget = new WidgetApp({
  target: document.body,
});

export default widget;
