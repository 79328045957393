<script>
  import Product from "./Product.svelte";

  export let products = [];
  let productId;

  const selectProduct = (id) => (productId = id);
  const resetProduct = () => (productId = undefined);
</script>

<li class="chat__products" data-name="productsGallery">
  {#if productId}
    <Product id={productId} collection={products} {selectProduct} />
    <button class="toggler" on:click={resetProduct}>Toggle</button>
  {:else}
    <div class="gallery gallery-{products.length}">
      {#each products as { id, title, formattedPrice, featuredImage }}
        {#if featuredImage}
          <button class="gallery__item" on:click={() => selectProduct(id)}>
            <img alt={title} class="gallery__img" src={featuredImage.src} />
          </button>
        {/if}
      {/each}
    </div>
  {/if}
</li>

<style>
  .chat__products {
    display: block;
    position: relative;
    margin-bottom: 10px;
    /* min-height: 215px; */
    padding: 22px 10px 10px;
    background: #fff;
    border: 2px solid #ededed;
    border-radius: 0 12px 12px 12px;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
  }

  .toggler {
    position: absolute;
    z-index: 2;
    display: block;
    top: 8px;
    left: 8px;
    text-indent: -30000px;
    width: 16px;
    height: 16px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAABdUlEQVRYhe2YMUrEQBSGvywpLATZQks9gdh4AK1XPIClIB7DRsHWMhZ6AEuXtbARrCwtLRTRrUXFUjYyYYoYZsmbTPJcZD4Igc2/M19eNnmTTbIsuwQGQIIOOTAEtiWz9ZTlsHMNpOG0JKdZwZ40LA7+FVEwlJkXTAUZcxILgtwE+GjB6RcSwTtgXTjeObDbkluBRFAqZ5jzyPZrjhdXRCJY5Qg4c3z+DYw9xnkTZG58BL+AeWAPuADuPb7blA2fu3gHeAKWgGtgLXDypGYr8BF8NmdkJRdbkqzF9zn4qi3Z5EGtKtm0k6hJhrQ6FUmJ4KSyd0k+diUpETwEToGHKceN5GZXkhLBA2DfdoppuCRXtQSlVCWPZ02Q0m/SrGpO2hiwyWKhjnGbS6645A8lCoaS2jf9xO61cHUlJ6aCI+WimEJcScOmgluCnHntfAdegJUwPz/iTRJKFAwlCobyL/5+o9RllpU7Ti6t4Cdw27FMlRwY/gCHGklUW8ypjAAAAABJRU5ErkJggg==")
      no-repeat 0 0 / contain;
  }

  .gallery {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(1, 1fr);
    /* grid-template-rows: repeat(1, 100vw); */
  }

  .gallery-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery-3 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 50vw);
  }

  .gallery-3 .gallery__item:first-child {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .gallery-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 50vw);
  }

  .gallery__item {
    width: 100%;
    height: 100%;
  }

  .gallery__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>
