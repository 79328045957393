<script>
  import Notifications from "svelte-notifications";

  import Notification from "pages/shared/Notification.svelte";
  import WidgetLayout from 'layouts/Widget.svelte';

  import "./assets/global.css";
</script>

<Notifications item={Notification}>
  <WidgetLayout />
</Notifications>
