<script>
  import { navigate } from "svelte-routing";
  import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from "constants/routes";

  import { invitationRequest, signUp, login, loginAsGuest, getMe } from "utils/api";

  import authStore from "stores/auth";
  import accountStore from "stores/account";
  import meStore from "stores/me";

  import FormErrors from "../shared/FormErrors.svelte";
  import FormInput from "../shared/FormInput.svelte";
  import FormActions from "../shared/FormActions.svelte";
  import Button from "../shared/Button.svelte";

  import Toggle from "./shared/Toggle.svelte";

  let errors;
  let full_name;
  let email;
  let password;
  let marketingOptIn = true;
  let isInvitationRequestSuccess = false;
  let loading = false;

  const { flow_id, guest_token } = window.luxlockCustomer;

  const onSubmit = async () => {
    try {
      loading = true;
      if ($accountStore && $accountStore.isPremium) {
        await invitationRequest({
          invitation_request: {
            full_name,
            email: email.trim(),
            marketing_opt_in: marketingOptIn,
          },
          client_id: window.luxlockAccountConfig.clientId,
          client_secret: window.luxlockAccountConfig.clientSecret,
        });

        isInvitationRequestSuccess = true;
        loading = false;
      } else {
        await signUp({
          user: {
            email: email.trim(),
            full_name,
            password,
            flow_id,
            guest_token,
            marketing_opt_in: marketingOptIn,
          },
          client_id: window.luxlockAccountConfig.clientId,
          client_secret: window.luxlockAccountConfig.clientSecret,
        });

        const token = await login({
          grant_type: "password",
          username: email.trim(),
          password,
          flow_id,
          guest_token,
          client_id: window.luxlockAccountConfig.clientId,
          client_secret: window.luxlockAccountConfig.clientSecret,
        });

        authStore.login(token);

        const me = await getMe();
        meStore.set(me);
        loading = false;
        navigate("/", { replace: true });
      }
    } catch ({ response }) {
      loading = false;
      isInvitationRequestSuccess = false;
      errors = response.data.errors;
    }
  };

  const onLoginAsGuest = async () => {
    try {
      const token = await loginAsGuest({
        user: {
          flow_id,
          guest_token,
        },
        client_id: window.luxlockAccountConfig.clientId,
        client_secret: window.luxlockAccountConfig.clientSecret,
      });
      authStore.login(token);
      navigate("/", { replace: true });
    } catch ({ response }) {
      errors = response.data.errors;
    }

    const me = await getMe();
    meStore.set(me);
  };
</script>

<div class="aligner">
  <div class="aligner__item aligner__item-top">
    <Toggle active="register" isPremium={$accountStore && $accountStore.isPremium}>
      <div class="header" slot="header">
        <h2 class="title">
          {#if $accountStore && $accountStore.registerPageTitle}
            {@html $accountStore.registerPageTitle}
          {:else}Live Shopping{/if}
        </h2>
        {#if $accountStore && $accountStore.registerPageText}
          {@html $accountStore.registerPageText}
        {:else}Get matched to shop live with a dedicated personal shopper and unlock premium services.{/if}
      </div>
    </Toggle>

    {#if $accountStore && !$accountStore.isPremium}
      <form on:submit|preventDefault={onSubmit}>
        <FormErrors {errors} />
        <FormInput placeholder="Full Name" bind:value={full_name} id="fullname" name="user.full_name" {errors} />
        <FormInput placeholder="Email Address" bind:value={email} id="email" name="user.email" {errors} />
        <FormInput
          placeholder="Create Password"
          bind:value={password}
          type="password"
          name="user.password"
          id="password"
          {errors} />

        {#if $accountStore && $accountStore.marketingOptInEnabled}
          <FormInput
            bind:value={marketingOptIn}
            type="checkbox"
            label={$accountStore.marketingOptInText}
            name="user.marketingOptIn"
            id="marketingOptIn"
            {errors} />
        {/if}

        <FormActions>
          <Button dataName="connectButton" block type="submit" disabled={loading}>Submit</Button>

          {#if $accountStore && $accountStore.guestAccessAllowed}
            <div class="as_guest">
              <Button type="button" transparent block on:click={onLoginAsGuest} data-name="continueAsGuest">
                Continue As Guest
              </Button>
            </div>
          {/if}
        </FormActions>
      </form>
    {:else if isInvitationRequestSuccess === true}
      Thank you for your enquiry.
      <br />
      We will get in touch with you shortly
    {:else}
      <form on:submit|preventDefault={onSubmit}>
        <FormErrors {errors} />
        <FormInput placeholder="Full Name" bind:value={full_name} id="fullname" name="full_name" {errors} />
        <FormInput placeholder="Email Address" bind:value={email} id="email" name="email" {errors} />
        {#if $accountStore && $accountStore.marketingOptInEnabled}
          <FormInput
            bind:value={marketingOptIn}
            type="checkbox"
            label={$accountStore.marketingOptInText}
            name="user.marketingOptIn"
            id="marketingOptIn"
            {errors} />
        {/if}
        <FormActions>
          <Button dataName="connectButton" block type="submit">Request Access</Button>
        </FormActions>
      </form>
    {/if}
  </div>

 <div class="auth__footer aligner__item aligner__item-bottom">
    By registering you are agreeing to our
    <a class="link" href={TERMS_AND_CONDITIONS_URL} target="_blank">Terms of Use</a>
    and
    <a class="link" href={PRIVACY_POLICY_URL} target="_blank">Privacy Policy.</a>
    <span class="auth__powered">Powered By LuxLock</span>
  </div>
</div>

<style>
  .title {
    margin-bottom: 7px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 300;
    color: #1f1f22;
  }

  .as_guest {
    margin-top: 8px;
  }

  .auth__footer {
    position: relative;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 300;
    line-height: 17px;
    color: #8f8f8f;
  }

  .auth__powered {
    display: block;
    margin: 18px 0;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    letter-spacing: 0.83px;
    font-weight: bold;
    color: #666666;
  }

  .link {
    text-decoration: none;
    color: #666;
  }

  .link:hover {
    text-decoration: underline;
  }

</style>
