<script>
  export let errors;
</script>

{#if errors && errors.title}
  <div class="title">{errors.title}</div>
{/if}

<style>
  .title {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
    color: red;
  }
</style>
