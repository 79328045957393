<script>
  import { link } from "svelte-routing";

  export let useLink = true;
  export let dataName;
  export let block = false;
  export let type = "button";
  export let href = undefined;
  export let target = "_self";
  export let transparent = false;
  export let disabled = false;

  let class_name = "";
  export { class_name as class };
</script>

{#if href}
  {#if useLink}
    <a on:click class="btn {class_name}" use:link data-name={dataName} class:block class:transparent {href}>
      <slot />
    </a>
  {:else}
    <a on:click class="btn {class_name}" target={target} data-name={dataName} class:block class:transparent {href}>
      <slot />
    </a>
  {/if}
{:else}
  <button on:click class="btn {class_name}" disabled={disabled} data-name={dataName} class:block class:transparent {type}>
    <slot />
  </button>
{/if}

<style>
  .btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 0 25px;
    height: 56px;
    min-width: 160px;
    line-height: 56px;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1.67px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    border-radius: 4px;
    outline: none;
    border: none;
    touch-action: manipulation;
    -webkit-appearance: none;
    transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s;
    color: #fff;
    background: #b09353;
    background-image: linear-gradient(-42deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.2) 50%);
  }

  .btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .btn:active {
    top: 1px;
  }

  :global(.btn svg) {
    margin-top: -2px;
    margin-right: 8px;
  }
  .btn-price {
    margin: 10px 0;
    height: 40px;
    line-height: 40px;
  }

  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .transparent {
    color: #b09353;
    background: transparent;
  }

  .transparentGrey {
    color: #999999;
    background: transparent;
  }
</style>
