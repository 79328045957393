<script>
  import accountStore from "stores/account";

  import Button from "../shared/Button.svelte";
</script>

<div class="aligner aligner-center">
  <div class="aligner__item">
    <h1>Be Right Back</h1>
    <p>
      I am finishing up with a client, if you’d like to browse new arrivals. For order inquiries please
      contact client services for immediate assistance.
    </p>

    {#if $accountStore && $accountStore.email}
      <div style="margin-bottom: 10px">
        <Button block target="_blank" href={`mailto:${$accountStore.email}`} primary>Send Email</Button>
      </div>
    {/if}

    <Button block target="_blank" useLink={false} href="https://dashboard.luxlock.com/auth" transparent>
      Preference Manager
    </Button>
  </div>
</div>

<style>
  h1 {
    position: relative;
    margin: 22px 0 16px;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
    text-align: center;
    color: #1f1f22;
  }
  p {
    text-align: center;
    margin: 0 0 27px;
    font-weight: 300;
    line-height: 19px;
    font-size: 14px;
    color: #757575;
  }

  .aligner__item {
    margin-top: -120px;
    text-align: center;
  }
</style>
