import { writable } from "svelte/store";

const initialState = undefined;
function createAccountStore() {
  const { subscribe, set } = writable(initialState);

  return {
    subscribe,
    set: (account) => set(account),
    reset: () => set(initialState),
  };
}

export default createAccountStore();
