<script>
  export let date;
</script>

{#if date}
  <li class="chat__date">{date}</li>
{/if}

<style>
  :global(.chat__date) {
    display: block;
    margin: 16px 0 10px;
    text-align: center;
    font-size: 12px;
    line-height: 17px;
    color: #545454;
  }
</style>
