import { writable } from "svelte/store";

const initialState = undefined;

function createConsultantStore() {
  const { subscribe, update, set } = writable(initialState);

  return {
    subscribe,
    set: (consultant) => set(consultant),
    update: (props) => update((consultant) => ({...consultant, ...props})),
    reset: () => set(initialState),
  };
}

export default createConsultantStore();
