<script>
  import { onMount, beforeUpdate } from "svelte";
  import StarIcon from "assets/icons/star.svg";

  export let afterRate;
  export let beforeRate;
  export let disabled = false;
  export let length = 5;
  export let readonly = false;

  export let value = 0;

  let arr = [];
  let over = 0;
  let rate = 0;

  $: if (value) {
    rate = convertValue(value);
    over = convertValue(value);
  }

  const convertValue = (val) => {
    if (val >= length) {
      val = length;
    } else if (val < 0) {
      val = 0;
    }
    return val;
  };
  const onOver = (index) => {
    if (!readonly) over = index;
  };
  const onOut = () => {
    if (!readonly) over = rate;
  };
  const setrate = (index) => {
    if (readonly) return false;
    if (typeof beforeRate === "function") {
      beforeRate(rate);
    }
    rate = index;
    if (typeof afterRate === "function") {
      afterRate(rate);
    }
  };
  const isFilled = (index) => {
    return index <= over;
  };

  const createArray = () => {
    for (let i = 1; i <= length; i++) {
      arr.push(i);
    }
  };
  beforeUpdate(() => {
    if (arr.length === 0) {
      createArray();
    }
  });
  onMount(() => {
    value = convertValue(value);
    rate = convertValue(value);
    over = convertValue(value);
  });
</script>

{#if length > 0}
  <div class="rate">
    {#each arr as n}
      <button
        type="button"
        key={n}
        class:hover={n <= over}
        class:filled={n <= rate || isFilled(n)}
        class="rate__star"
        data-name={`star${n}`}
        on:mouseover={() => {
          onOver(n);
        }}
        on:mouseout={() => {
          onOut(n);
        }}
        on:click={() => {
          setrate(n);
        }}
        on:keyup={() => {
          onOver(n);
        }}
        on:keyup.enter={() => {
          setrate(n);
        }}
        {disabled}>

        {@html StarIcon}

        <!-- <svg class="icon">
          <use xlink:href="#icon-star" />
        </svg> -->
      </button>
    {/each}
  </div>
{/if}

<style>
  .rate {
    cursor: default;
    margin-bottom: 18px;
  }

  .rate:hover .rate__star {
    fill: none;
    stroke: #5b5b5b;
  }

  .rate__star {
    display: inline-block;
    padding: 5px;
    fill: none;
    stroke: #5b5b5b;
    cursor: pointer;
    background: none;
    border: 0;
    /* box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.22); */
  }

  .rate:hover .rate__star.hover {
    fill: #ddbe78;
    stroke: #ddbe78;
  }
  .rate__star.filled {
    stroke: #ddbe78;
    fill: #ddbe78;
  }
  .rate__star[disabled] {
    opacity: 0.8;
  }
  .rate__star.hover[disabled],
  .rate__star.filled[disabled] {
    stroke: #ddbe78;
    fill: #ddbe78;
    opacity: 0.6;
  }
</style>
