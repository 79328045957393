<script>
  import { Router, Route } from "svelte-routing";
  import SignIn from "./SignIn.svelte";
  import SignUp from "./SignUp.svelte";
  import ResetPassword from "./ResetPassword.svelte";
</script>

<div class="auth">
  <div class="auth__container">
    <div class="auth__content">
      <Router basepath="/auth">
        <Route path="/sign_in" component={SignIn} />
        <Route path="/sign_up" component={SignUp} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/" component={SignUp} />
      </Router>
    </div>
  </div>
</div>

<style>
  .auth {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
  }

  .auth__container{
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .auth__content {
    flex: 1;
    margin: 16px 0;
  }


</style>
