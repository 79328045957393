import { writable } from "svelte/store";

const initialState = undefined;

function createMeStore() {
  const { subscribe, set } = writable(initialState);

  return {
    subscribe,
    set: (me) => set(me),
    reset: () => set(initialState),
  };
}

export default createMeStore();
