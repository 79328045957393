<script>
  // import { getNotificationsContext } from "svelte-notifications";
  import { navigate } from "svelte-routing";

  import consultantStore from "stores/consultant";
  import meStore from "stores/me";
  import authStore from "stores/auth";

  import { getIn } from "utils/ramda";
  import { signUp, logout, postConsultantRate } from "utils/api";

  import Avatar from "../shared/Avatar.svelte";
  import Button from "../shared/Button.svelte";
  import FormErrors from "../shared/FormErrors.svelte";
  import FormInput from "../shared/FormInput.svelte";
  import Rate from "../shared/Rate.svelte";

  let value = 4;
  let comment;
  let full_name;
  let email;
  let password;
  let errors;
  let loading = false;

  const { flow_id, guest_token } = window.luxlockCustomer;

  // const { addNotification } = getNotificationsContext();

  const afterRate = (rate) => {
    value = rate;
  };

  const onSubmit = async () => {
    loading = true;
    if ($meStore.isGuest) {
      signUp({
        user: {
          email,
          full_name,
          password,
          flow_id,
          guest_token,
        },
        client_id: window.luxlockAccountConfig.clientId,
        client_secret: window.luxlockAccountConfig.clientSecret,
      });
    }

    try {
      await postConsultantRate({
        rating: { value, comment },
      });

      try {
        await logout();

        // addNotification({
        //   id: "consultantRate",
        //   text: `Thank you! See you later.`,
        //   type: "success",
        //   position: "top-center",
        //   removeAfter: 5000,
        // });

        loading = false;
        authStore.logout();
        navigate("/auth/sign_in");
      } catch (err) {
        loading = false;
      }
    } catch ({ response }) {
      loading = false;
      errors = getIn("data.errors", response);
      if (errors.status === 404) {
        await logout();
        authStore.logout();
        navigate("/auth/sign_in");
      }
    }
  };
</script>

<div class="aligner aligner-center">
  <div class="aligner__item">
    {#if $consultantStore}
      <form class="rate" on:submit|preventDefault={onSubmit}>
        <FormErrors {errors} />

        <Avatar class="avatar-center" contact={$consultantStore} size={80} />
        <h1>
          {$meStore.isGuest ? "Thank You" : `Thank You ${$meStore.firstName}!`}
        </h1>
        {#if $meStore && !$meStore.isGuest}
          <p>I look forward to continuing our conversation and shopping with you again.</p>
        {/if}

        <Rate {afterRate} length={5} {value} />
        <FormInput
          autofocus={true}
          placeholder="Leave a note (optional)"
          dataName="commentField"
          bind:value={comment}
          id="comment"
          type="textarea"
          {errors} />

        {#if $meStore && $meStore.isGuest}
          <p>Pleasure shopping with you, please register to save your preferences.</p>

          <FormInput
            placeholder="Full Name"
            bind:value={full_name}
            id="fullname"
            name="user.full_name"
            {errors} />
          <FormInput placeholder="Email" bind:value={email} id="email" name="user.email" {errors} />
          <FormInput
            placeholder="Password"
            bind:value={password}
            type="password"
            name="user.password"
            id="password"
            {errors} />
        {/if}
        <Button block dataName="sendReview" type="submit" disabled={loading}>Submit</Button>
      </form>
    {/if}
  </div>
</div>

<style>
  h1 {
    position: relative;
    margin: 22px 0 16px;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
    text-align: center;
    color: #1f1f22;
  }
  p {
    text-align: center;
    margin: 0 0 7px;
    font-weight: 300;
    line-height: 19px;
    font-size: 14px;
    color: #757575;
  }

  .aligner {
    padding-top: 40px;
  }

  .aligner__item {
    margin-top: -40px;
    text-align: center;
  }

  .rate {
    margin: 20px 0;
    text-align: center;
  }
</style>
