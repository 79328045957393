<script>
  import { afterUpdate, beforeUpdate, onDestroy, onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import ActionCable from "actioncable";

  import SubmitIcon from "assets/icons/submit.svg";

  import authStore from "stores/auth";
  import accountStore from "stores/account";
  import meStore from "stores/me";
  import consultantStore from "stores/consultant";
  import messagesStore from "stores/messages";

  import { getConsultant, getMessages, postMessage } from "utils/api";
  import { isDefined } from "utils/ramda";
  import { formatChatDate } from "utils/formats";

  import { textAreaResize } from "utils/textAreaResize";

  import InfiniteScroll from "../shared/InfiniteScroll.svelte";
  import Loader from "../shared/Loader.svelte";

  import DateItem from "./shared/DateItem.svelte";
  import TextItem from "./shared/TextItem.svelte";
  import ProductsItem from "./shared/ProductsItem.svelte";
  import TypingItem from "./shared/TypingItem.svelte";

  let autoscroll = true;
  let connection;
  let isLastPage = true;
  let isLoading = false;
  let isLoadingMore = false;
  let isTyping = false;
  let newMessage = "";
  let nextPage = 1;
  let scrollable;
  let scrollHeight;
  let stylistTyping = false;
  let submitting = false;
  let typingTimer;
  let textarea;
  let accessToken;

  const groupByDate = (messages) =>
    messages.reduce((message, a) => {
      const date = new Date(a.createdAt);
      const formatedDate = formatChatDate(date);

      message[formatedDate] = [...(message[formatedDate] || []), a];
      return message;
    }, {});

  const loadMore = async () => {
    isLoadingMore = true;
    await fetchMessages();
    isLoadingMore = false;
  };

  const fetchMessages = async () => {
    const { data, meta } = await getMessages({ page: nextPage });
    const messages = (data && data.reverse()) || [];

    nextPage = meta.nextPage;
    isLastPage = meta.isLastPage;

    messagesStore.fetch(messages);
  };

  onMount(async () => {
    isLoading = true;

    try {
      const consultant = await getConsultant();
      consultantStore.set(consultant);
    } catch {
      isLoading = false;
      navigate("/unavailable");
    }

    if ($consultantStore && $consultantStore.state === "stale") {
      navigate("/consultant/rate");
    }

    await fetchMessages();
    isLoading = false;

    if (isDefined($authStore)) {
      accessToken = $authStore.access_token;
    }

    const cableUrl = `${process.env.CABLE_URL}?access_token=${accessToken}`;
    const consumer = ActionCable.createConsumer(cableUrl);

    connection = consumer.subscriptions.create(
      {
        channel: "Account::ChatChannel",
        account_id: $accountStore.id,
        contact_id: $meStore.contactId,
      },
      {
        initialized() {},
        connected() {},
        received(res) {
          const { action, data } = res;

          if (action === "assignment_stale") {
            consultantStore.update({
              state: "stale",
            });
            navigate("/consultant/rate");
          }

          if (action === "message") {
            messagesStore.update(data);
          }

          stylistTyping = res.typing && res.contactId !== $meStore.contactId;
        },
        disconnected() {},
        rejected() {},
      },
    );
  });

  onDestroy(() => connection && connection.unsubscribe());

  const onSubmit = async () => {
    isTyping = false;
    connection.perform("typing", { typing: false });

    if (newMessage && !submitting) {
      submitting = true;
      await postMessage({ message: { text: newMessage } });
      newMessage = "";
      resetTextAreaHeight();
      submitting = false;
    }
    return scrollable.scrollTo(0, scrollable.scrollHeight);
  };

  const resetTextAreaHeight = () => {
    textarea.style = "height: 50px";
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      resetTextAreaHeight();
      onSubmit();
    }
  };

  const onTyping = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      isTyping = false;
      connection.perform("typing", { typing: false });
    }

    if (!isTyping) {
      isTyping = true;
      connection.perform("typing", { typing: true });
    }

    clearTimeout(typingTimer);

    typingTimer = setTimeout(() => {
      isTyping = false;
      connection.perform("typing", { typing: false });
    }, 1000);
  };

  beforeUpdate(() => {
    if (scrollable) {
      autoscroll = scrollable.offsetHeight + scrollable.scrollTop > scrollable.scrollHeight - 40;
      scrollHeight = scrollable.scrollHeight;
    }
  });

  afterUpdate(() => {
    if (autoscroll && scrollable) {
      return scrollable.scrollTo(0, scrollable.scrollHeight);
    }

    if (scrollable && isLoadingMore) {
      const newScrollHeight = scrollable.scrollHeight;
      return scrollable.scrollTo(0, newScrollHeight - scrollHeight);
    }
  });
</script>

<div class="container__chat">
  {#if isLoading}
    <Loader />
  {:else}
    <div class="container">
      <div class="scrollable" bind:this={scrollable}>
        {#if scrollable}
          <InfiniteScroll hasMore={!isLastPage} threshold={0} on:loadMore={loadMore} />
        {/if}

        <ul>
          {#each Object.entries(groupByDate($messagesStore)) as [date, messages] (date)}
            <DateItem {date} />

            {#each messages as message}
              {#if message.text}
                <TextItem {...message} />
              {/if}

              {#if message.products}
                <ProductsItem products={message.products} />
              {/if}
            {/each}
          {/each}

          {#if stylistTyping}
            <TypingItem />
          {/if}
        </ul>
      </div>

      <form on:submit|preventDefault={onSubmit}>
        <textarea
          autofocus
          autocomplete="off"
          on:keyup|preventDefault={onTyping}
          on:keydown={onKeyDown}
          placeholder="Type your message"
          bind:value={newMessage}
          bind:this={textarea}
          rows={1}
          use:textAreaResize
          type="text"
          data-name="entryField" />
        <button class="submit_button" on:click|preventDefault={onSubmit} data-name="sendButton">
          {@html SubmitIcon}
        </button>
      </form>
    </div>
  {/if}
</div>

<style>
  .container__chat {
    margin: 0 -50px -10px;
  }
  .container {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100vh - 70px);
    background: #f9f9f9;
  }

  .container::before {
    content: "";
    z-index: 1000;
    width: 100%;
    height: 6px;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    opacity: 0.3;
    background-image: linear-gradient(270deg, rgba(244, 244, 244, 0) 55%, #f4f4f4 100%),
      linear-gradient(90deg, rgba(244, 244, 244, 0) 55%, #f4f4f4 100%),
      linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  }

  .scrollable {
    align-self: flex-start;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding: 0 28px;
  }

  form {
    position: relative;
    width: 100%;
    align-self: flex-end;
  }

  textarea {
    position: relative;
    display: block;
    bottom: 0;
    padding: 18px 50px 18px 16px;
    width: 100%;
    resize: none;
    max-height: 100px;
    font-size: 16px;
    line-height: 1.35;
    color: #545454;
    outline: 0;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: inset -4px -4px 8px 0 #fafbff, inset 4px 4px 8px 0 #bdbdbd;
    border-radius: 4px;
    transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    font-family: "M PLUS 1p", Helvetica, -apple-system, system-ui, Segoe UI, Ubuntu, Open Sans, Helvetica Neue,
      Arial, sans-serif;
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width:1024px) {
    textarea{
      font-size: 16px;
    }
  }

  textarea::placeholder {
    color: #999999;
  }

  .submit_button {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -7px;
    padding: 0;
    border: 0;
    background: none;
    outline: none;
    transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .submit_button:hover {
    opacity: 0.75;
  }
</style>
