<script>
  import { IFrameHelper } from 'utils/helpers';
  import Button from "../shared/Button.svelte";

  const { proactiveMessageTitle,  proactiveMessageBody } = window.luxlockAccountConfig;
</script>

<div class="aligner aligner-center">
  <div class="aligner__item">
    <h1>{proactiveMessageTitle}</h1>
    <p>{proactiveMessageBody}</p>
    <Button href="/auth/sign_up" block on:click={() => IFrameHelper.sendMessage({ event: 'toggleProactive' })}>Connect Now</Button>
  </div>
</div>

<style>
  h1 {
    position: relative;
    margin: 22px 0 16px;
    font-weight: 300;
    font-size: 40px;
    letter-spacing: 3px;
    line-height: 40px;
    text-align: left;
    text-transform: uppercase;
    color: #b09353;
  }
  p {
    display: block;
    margin: 0 0 18px;
    font-weight: 300;
    font-size: 14px;
    line-height: 160%;
    text-align: left;
    color: #757575;
  }

  .aligner__item {
    margin-top: -20px;
    text-align: center;
  }
</style>
