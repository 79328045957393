<script>
  import meStore from "stores/me";
  import { formatDateTime } from "utils/formats.js";

  export let contact = {};
  export let createdAt;
  export let id;
  export let text;
</script>

{#if text}
  <li
    class="chat__message"
    class:chat__message-reverse={contact.id !== $meStore.contactId}
    data-name={contact.id !== $meStore.contactId ? 'bubbleStylist' : 'bubbleShopper'}
    {id}>
    <div>
      <div class="chat__bubble">
        {@html text}
      </div>
      <span class="chat__time">{formatDateTime(createdAt)}</span>
    </div>
  </li>
{/if}

<style>
  :global(.chat__message) {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    word-break: break-word;
    margin-bottom: 20px;
  }

  :global(.chat__bubble) {
    padding: 9px 12px;
    color: #212121;
    font-size: 14px;
    line-height: 19px;
    background: rgba(217, 217, 217, 0.5);
    border-radius: 12px 12px 0 12px;
  }

  :global(.chat__bubble a) {
    color: #0366d6;
    text-decoration: underline;
  }

  :global(.chat__bubble a:hover) {
    text-decoration: none;
  }

  :global(.chat__bubble-transparent) {
    background: none;
  }

  :global(.chat__time) {
    display: block;
    margin: 4px 0 0 0;
    text-align: right;
    font-size: 11px;
    line-height: 11px;
    color: #999999;
    opacity: 0.75;
  }

  :global(.chat__message .avatar) {
    margin: 0 0 0 10px;
  }

  :global(.chat__message-reverse) {
    flex-direction: row-reverse;
  }

  :global(.chat__message-reverse .avatar) {
    margin: 0 10px 0 0;
  }

  :global(.chat__message-reverse .chat__bubble) {
    color: #1f1f22;
    border-radius: 0 12px 12px 12px;
    background: #fff;
    box-shadow: none;
  }

  :global(.chat__message-reverse .chat__time) {
    text-align: left;
  }
</style>
